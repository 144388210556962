.root {
  display: flex;
  border-radius: 0.25rem;
  margin-top: 1.5rem;
  cursor: pointer;
  background-color: var(--white);
}

.root:hover {
  box-shadow: 0px 11px 12px -4px rgba(138, 132, 130, 0.21);
  transition: 0.25s;
}

.root > .container {
  width: 11.5625rem;
  height: 5rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  overflow: hidden;
}

.root > .container > div:hover {
  transform: scale(1.2);
}

.root > .container > div {
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  transition: transform ease 0.3s;
  animation: fadeIn 3s;
  -moz-animation: fadeIn 3s; /* Firefox */
  -webkit-animation: fadeIn 3s; /* Safari and Chrome */
  -o-animation: fadeIn 3s; /* Opera */
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.root > div:last-child {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

.root > div:last-child > p {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.root > div:last-child > img {
  width: 1.5rem;
  height: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .root {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    border-radius: 4px;
    width: 170px;
    min-width: 170px;
  }
  .root > .container {
    width: 100%;
    height: 80px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .root > .container > img {
    width: fill;
    height: fill;
    object-fit: cover;
  }
  .root > div:last-child {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: center;
    width: fill;
    background-color: var(--white);
  }
  .root > div:last-child > p {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    margin-right: 8px;
  }
  .root > div:last-child > img {
    width: 24px;
    height: 24px;
  }
}
