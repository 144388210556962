.root {
  background-color: var(--white-bg);
  border-radius: 0.5rem;
  padding: 1rem;
  min-width: 15.5rem;
  max-width: 15.5rem;
  width: 15.5rem;
  min-height: 11.125rem;
  box-shadow: 0px 11px 12px -4px rgba(138, 132, 130, 0.21);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.root > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  height: fill;
}

.root > div:nth-child(1) > img {
  width: 14.5rem;
  height: 14.5rem;
  border-radius: 0.25rem;
  object-fit: cover;
  margin-bottom: 1rem;
  align-self: center;
}

.root > div:nth-child(1) > p {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-800);
  width: fill;
  font-weight: 800;
}

.root > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.root > div:nth-child(2) > img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.root > div:nth-child(2) > a {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
}

.root > div:nth-child(2) > a:hover {
  color: #944EA1;
  text-decoration: underline;
}

.root > div:nth-child(2) > a:focus {
  color: #3F2245;
}

@media only screen and (max-width: 768px) {
  .root {
    border-radius: 8px;
    padding: 16px;
    width: 248px;
    max-width: 248px;
    min-width: 248px;
    min-height: 178px;
  }
  
  .root > div:nth-child(1) > img {
    border-radius: 4px;
    max-height: 232px;
    max-width: 232px;
    height: 232px;
    width: 232px;
    margin-bottom: 16px;

  }
  
  .root > div:nth-child(1) > p {
    font-size: 14px;
    line-height: 20px;
  }
  
  .root > div:nth-child(2) > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  .root > div:nth-child(2) > a {
    font-size: 12px;
    line-height: 16px;
  }
}