.root {
  padding: 2.5rem 0 0;
}

.root h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.625rem;
  margin: 0;
  margin-bottom: 1rem;
}

.root > p {
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
}

.root > .why {
  margin-top: 6.5rem;
}

.root > .why > div {
  margin-top: 1.5rem;
  padding: 0 7.75rem;
  display: grid;
  grid-template-columns: 17.875rem 17.875rem 17.875rem 17.875rem;
  grid-template-rows: 9.5rem;
  column-gap: 1rem;
}

.root > .workflow {
  background: linear-gradient(42.88deg, rgba(255, 188, 150, 0.32) 11.21%, rgba(217, 240, 247, 0.58) 33.47%, #FFFDFD 48.85%);
  margin-top: 4rem;
  padding: 5rem 7.75rem 5rem 27.0625rem;
  display: grid;
  grid-template-columns: 19.4375rem 25.875rem;
  grid-template-rows: 25.5625rem;
  column-gap: 1.5rem;
}

.root > .workflow > .title {
  padding: 0px 1.5rem 4.1875rem;
  text-align: left;
}

.root > .workflow > .title > h2 {
  margin: 0;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.25rem;
  margin-bottom: 1.5rem;
  margin-top: 5.625rem;
}

.root > .workflow > .title > p {
  font-family: 'Avenir', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
  margin: 0;
}

.root > .workflow > .cards {
  display: grid;
  grid-template-columns: 22.875rem;
  grid-template-rows: 7.3125rem 7.3125rem 7.3125rem;
  row-gap: 1.8125rem;
  padding-left: 3rem;
  
}

.root > .workflow > .cards > div {
  padding: 1rem 1rem 1rem 2.5rem;
  background-color: #FBFAFF;
  border-radius: 0.5rem;
  border: 0.125rem solid #EFEFEF;
  text-align: left;
  box-shadow: 0px 11px 12px -4px rgba(138, 132, 130, 0.21);
}

.root > .workflow > .cards > div > img {
  position: relative;
  bottom: 5.5rem;
  right: 5rem;
  width: 3.5rem;
  height: 4.875rem;
  object-position: left;
  object-fit: contain;
}

.root > .workflow > .cards > div > h4 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.25rem;
}

.root > .workflow > .cards > div > p {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.root > .cover {
  text-align: left;
  height: 0;
}

.root > .cover > img {
  position: relative;
  bottom: 30.55rem;
  right: 0.03rem;
  width: 34.5625rem;
  height: 30.5625rem;
}

.root > .formContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 7.8125rem;
  padding: 3.5rem 14.0625rem;
}

.root > .formContent > img {
  width: 30.125rem;
  height: fill;
  object-fit: cover;
  border-radius: 1rem;
}

.root > .formContent > div > p {
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
  margin-bottom: 1.5rem;
}

.stepper {
  padding: 1rem 1.5rem;
  border-radius: 2.5rem;
  background-color: var(--light-blue);
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.stepper > p {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--primary);
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  width: fill;
}

.stepper > p.active {
  color: var(--gray-25);
  background-color: var(--primary);
}

.stepper > .line {
  border: 1px solid var(--primary);
  width: fill;
  height: 0;
  margin: 0 0.875rem;
  border-radius: 2.5rem;
  
}

@media only screen and (max-width: 768px) {
  .root {
    padding: 96px 0 24px;
  }
  
  .root h3 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 16px;
    margin-top: 24px;
    padding: 0 40px;
  }
  
  .root > p {
    font-size: 16px;
    line-height: 20px;
    padding: 0 40px;
  }
  
  .root > .why {
    margin-top: 72px;
  }
  
  .root > .why > div {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .root > .why > div > div:not(:last-child) {
    margin-bottom: 16px;
  }
  
  .root > .workflow {
    background: linear-gradient(42.88deg, rgba(255, 188, 150, 0.32) 11.21%, rgba(217, 240, 247, 0.58) 33.47%, #FFFDFD 48.85%);
    margin-top: 32px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .root > .workflow > .title {
    padding: 24px 40px 0;
    text-align: left;
    width: fill;
    margin-bottom: 24px;
  }
  
  .root > .workflow > .title > h2 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
    margin-top: 5.625rem;
  }
  
  .root > .workflow > .title > p {
    font-size: 16px;
    line-height: 22px;
  }
  
  .root > .workflow > .cards {
    padding: 0 40px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: fill;
    padding-left: 48px;
  }
  
  .root > .workflow > .cards > div {
    padding: 16px 16px 16px 40px;
    border-radius: 8px;
    border: 2px solid #EFEFEF;
    text-align: left;
    box-shadow: 0px 11px 12px -4px rgba(138, 132, 130, 0.21);
    height: 85px;
    width: fill;
    margin-left: 24px;
  }
  
  .root > .workflow > .cards > div > img {
    position: relative;
    bottom: 68px;
    right: 80px;
    width: 56px;
    height: 78px;
    object-position: left;
    object-fit: contain;
  }
  
  .root > .workflow > .cards > div > h4 {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 20px;
  }
  
  .root > .workflow > .cards > div > p {
    font-size: 14px;
    line-height: 20px;
  }
  
  .root > .cover {
    display: none;
  }

  
  .root > .formContent {
    display: block;
    padding: 56px 40px;
  }

  .root > .formContent > img {
    display: none;
  }

  .root > .formContent > div > p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  .stepper {
    padding: 16px 24px;
    border-radius: 40px;
    margin-bottom: 24px;
  }

  .stepper > p {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 16px;
    border-radius: 24px;
    justify-content: center;
  }
  
  
  .stepper > .line {
    border: 1px solid var(--primary);
    margin: 0 14px;
    border-radius: 40px;
  }
}

@media only screen and (max-width: 425px) {
  .root > .workflow > .cards > div > img {
    bottom: 88px;
  }
  .root > .workflow > .cards > div {
    height: 95px;
  }
  .stepper > p:last-child {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: flex-start;
  }
  .stepper > .line {
    max-width: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .root > .workflow > .cards > div {
    height: 110px;
  }
}