.root > div {
  margin-bottom: 1.5rem;
}

.root > button {
  width: fill;
  padding: 1rem;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 768px) {
  .root > div {
    margin-bottom: 24px;
  }
  
  .root > button {
    padding: 16px !important;
    border-radius: 4px;
  }
}