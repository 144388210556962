.root {
  padding: 1.5rem 7.75rem;
  text-align: left;
}

.root > .header > img {
  height: 5.4375rem;
  width: 14.6875rem;
}

.heading1 {
  text-align: left;
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.25rem;
}

.heading2 {
  font-family: 'Avenir', sans-serif;
  text-align: left;
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
}

.galleries {
  display: grid;
  grid-template-columns: 0 44.875rem 28.125rem;
  padding-top: 2.5rem;
}

.satu { 
  grid-area: satu; 
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  animation: leftFadeIn 3s;
  -moz-animation: leftFadeIn 3s; /* Firefox */
  -webkit-animation: leftFadeIn 3s; /* Safari and Chrome */
  -o-animation: leftFadeIn 3s; /* Opera */
}
.dua { 
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
  animation: rightFadeIn 3.5s;
  -moz-animation: rightFadeIn 3.5s; /* Firefox */
  -webkit-animation: rightFadeIn 3.5s; /* Safari and Chrome */
  -o-animation: rightFadeIn 3.5s; /* Opera */
}
.tiga { 
  grid-area: tiga; 
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 4;
  animation: bottomFadeIn 2s;
  -moz-animation: bottomFadeIn 2s; /* Firefox */
  -webkit-animation: bottomFadeIn 2s; /* Safari and Chrome */
  -o-animation: bottomFadeIn 2s; /* Opera */
}
.empat { 
  grid-area: empat; 
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 4;
  animation: bottomFadeIn 2s;
  -moz-animation: bottomFadeIn 2s; /* Firefox */
  -webkit-animation: bottomFadeIn 2s; /* Safari and Chrome */
  -o-animation: bottomFadeIn 2s; /* Opera */
}
.lima { 
  grid-area: lima; 
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 3;
  animation: leftFadeIn 3s;
  -moz-animation: leftFadeIn 3s; /* Firefox */
  -webkit-animation: leftFadeIn 3s; /* Safari and Chrome */
  -o-animation: leftFadeIn 3s; /* Opera */
}
.enam { 
  grid-area: enam;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 7;
  animation: leftFadeIn 2.5s;
  -moz-animation: leftFadeIn 2.5s; /* Firefox */
  -webkit-animation: leftFadeIn 2.5s; /* Safari and Chrome */
  -o-animation: leftFadeIn 2.5s; /* Opera */
}
.tujuh { 
  grid-area: tujuh; 
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 6;
  animation: rightFadeIn 4s;
  -moz-animation: rightFadeIn 4s; /* Firefox */
  -webkit-animation: rightFadeIn 4s; /* Safari and Chrome */
  -o-animation: rightFadeIn 4s; /* Opera */
}
.delapan {
  grid-area: delapan;
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 4;
  grid-row-end: 9;
  object-position: top;
  animation: rightFadeIn 2s;
  -moz-animation: rightFadeIn 2s; /* Firefox */
  -webkit-animation: rightFadeIn 2s; /* Safari and Chrome */
  -o-animation: rightFadeIn 2s; /* Opera */
}
.sembilan { 
  grid-area: sembilan; 
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 7;
  animation: bottomFadeIn 4s;
  -moz-animation: bottomFadeIn 4s; /* Firefox */
  -webkit-animation: bottomFadeIn 4s; /* Safari and Chrome */
  -o-animation: bottomFadeIn 4s; /* Opera */
}
.sepuluh { 
  grid-area: sepuluh; 
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 7;
  grid-row-end: 9;
  animation: rightFadeIn 3s;
  -moz-animation: rightFadeIn 3s; /* Firefox */
  -webkit-animation: rightFadeIn 3s; /* Safari and Chrome */
  -o-animation: rightFadeIn 3s; /* Opera */
}
.sebelas { 
  grid-area: sebelas; 
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 7;
  grid-row-end: 9;
  animation: leftFadeIn 2s;
  -moz-animation: leftFadeIn 2s; /* Firefox */
  -webkit-animation: leftFadeIn 2s; /* Safari and Chrome */
  -o-animation: leftFadeIn 2s; /* Opera */
}

.galleries > div:nth-child(2) > button { 
  grid-area: button; 
  padding: 1rem;
  text-align: center;
  justify-content: center;
  line-height: 1rem;
  z-index: 2;
  box-shadow: 0px 11px 12px -4px rgba(138, 132, 130, 0.21);
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
  grid-row-end: 7;
  transition: 0.25s;
}

.galleries > div:nth-child(2) > button:hover {
  transform: scale(1.075);
}

.galleries > div:nth-child(2) > button > p { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.galleries > div:nth-child(2) > button > p > img { 
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

.galleries > div:nth-child(2) > div {
  overflow: hidden;
  border-radius: 0.25rem;
}

.galleries > div:nth-child(2) > div > div {
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  transition: transform ease 0.3s;
}

@keyframes leftFadeIn {
  0% { 
    opacity: 0; 
    transform: translate3d(-100%, 0, 0);
  }
  100% { 
    opacity: 1; 
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rightFadeIn {
  0% { 
    opacity: 0; 
    transform: translate3d(0, -100%, 0);
  }
  100% { 
    opacity: 1; 
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bottomFadeIn {
  0% { 
    opacity: 0; 
    transform: translate3d(0, 0, 100%);
  }
  100% { 
    opacity: 1; 
    transform: translate3d(0, 0, 0);
  }
}

.galleries > div:nth-child(2) > div > div:hover {
  transform: scale(1.2);
}

.galleries > div:nth-child(2) {
  height: 23rem;
  max-height: 23rem;
  margin-right: 1.5rem;
  display: grid;
  grid-template-columns: repeat(7, 5rem);
  grid-template-rows: repeat(7, 2.3rem);
  gap: 1rem;
  overflow: hidden;
}

.overlay {
  width: 43.375rem;
  height: 4.5rem;
  z-index: 1;
  position: relative;
  top: 19rem;
  background: linear-gradient(0deg, #FFF5F1 13%, rgba(255, 255, 255, 0.1) 67.57%);
}

.galleries > div:nth-child(3) {
  padding: 1.5rem;
  background: linear-gradient(24.22deg, #D9F0F7 -46.53%, rgba(217, 240, 247, 0) 88.83%);
}

.galleries > div:nth-child(3) > p {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.contentWhyUs {
  border-radius: 0.25rem;
  background-color: var(--white-bg);
  padding: 3rem 2.5rem;
}

.contentWhyUs > h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--gray-800);
  margin: 0;
}

.contentWhyUs > p {
  margin: 0;
  margin-top: 0.5rem;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-800);
  font-family: 'Avenir', sans-serif;
}

.contentWhyUs > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
}

.testimonials {
  padding-top: 5rem;
  height: fit-content;
  max-height: 35rem;
  overflow: hidden;
}

.testimonials > h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--gray-800);
  margin: 0;
}

.testimonials > p {
  margin: 0;
  margin-top: 0.5rem;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-800);
  font-family: 'Avenir', sans-serif;
}

.testimonials > div:not(.overlayLeft, .overlayRight) {
  padding-bottom: 1.5rem;
  display: flex;
  overflow: auto;
  margin-top: 1.5rem;
  height: fit-content;
  min-height: 15rem;
  max-height: 35rem;
}

.testimonials > div > div > div:first-child {
  width: fit-content;
  margin-left: 4rem;
}

.overlayLeft {
  background: linear-gradient(90deg, #FFF5F1 1.23%, rgba(255, 255, 255, 0) 67.57%);
  position: relative;
  /* height: 15.125rem; */
  height: 100%;
  z-index: 2;
  top: -30rem;
  left: -0.05rem;
  width: 15rem;
}

.overlayRight {
  background: linear-gradient(270deg, #FFF5F1 1.23%, rgba(255, 255, 255, 0) 67.57%);
  position: relative;
  /* height: 15.125rem; */
  height: 100%;
  z-index: 2;
  top: -70rem;
  right: -0.1rem;
  width: 15rem;
  float: right;
}

@media only screen and (max-width: 768px) {
  .root {
    margin-top: 80px;
    padding: 24px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .root > .header > img {
    width: 280px;
    height: 87px;
  }

  .heading1 {
    margin-top: 24px;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    padding: 0 40px;
}
  
  .heading2 {
    margin-top: 8px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    padding: 0 40px;
  }

  .galleries {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 0 543px 227px;
    row-gap: 24px;
    padding-top: 0;
    width: 100vw;
  }

  .satu { 
    grid-area: satu; 
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .dua { 
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .tiga { 
    grid-area: tiga; 
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .empat { 
    grid-area: empat; 
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .lima { 
    grid-area: lima; 
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .enam { 
    grid-area: enam;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 7;
  }
  .tujuh { 
    grid-area: tujuh; 
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  .delapan {
    grid-area: delapan;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 9;
    object-position: top;
  }
  .sembilan { 
    grid-area: sembilan; 
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 7;
  }
  .sepuluh { 
    grid-area: sepuluh; 
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 9;
  }
  .sebelas { 
    grid-area: sebelas; 
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 9;
  }

  .galleries > div:nth-child(2) {
    margin: 0;
    height: 543px;
    max-height: 543px;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 100px);
    grid-template-rows: repeat(7, 40px);
    gap: 16px;
    overflow-x: hidden;
  }

  .galleries > div:nth-child(2) > img {
    border-radius: 4px;
  }

  .galleries > div:nth-child(2) > button {
    width: fill;
    height: 48px;
    border-radius: 8px;
    padding: 16px;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 7;
  }

  .galleries > div:nth-child(2) > button > p > img { 
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }

  .galleries > div:nth-child(3) {
    padding: 24px 0;
  }
  .galleries > div:nth-child(3) > p  {
    padding-left: 40px;
    font-size: 24px;
    line-height: 27px;
    text-align: left;
    margin-bottom: 16px;
  }

  .galleries > div:nth-child(3) > div {
    display: flex;
    overflow: scroll;
    position: relative;
    padding: 0 40px 20px;
  }

  .galleries > div:nth-child(3) > div > div {
    margin-right: 10px;
  }

  .overlay {
    width: 100vw;
    height: 552px;
    top: 16px;
  }

  .contentWhyUs {
    border-radius: 0;
    background-color: var(--white-bg);
    text-align: left;
    padding: 40px 0;
    width: 100vw;
  }
  
  .contentWhyUs > h3 {
    font-size: 24px;
    line-height: 26px;
    margin: 0;
    padding: 0 40px;
  }
  
  .contentWhyUs > p {
    padding: 0 40px;
    margin: 0;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }
  
  .contentWhyUs > div {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }
  
  .contentWhyUs > div > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .testimonials {
    padding-top: 40px;
    text-align: left;
    width: 100vw;
    max-height: fit-content;
    height: fit-content;
  }
  
  .testimonials > h3 {
    font-size: 24px;
    line-height: 26px;
    padding: 0 40px;
  }
  
  .testimonials > p {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    padding: 0 40px;
  }
  
  .testimonials > div:not(.overlayLeft, .overlayRight) {
    display: flex;
    overflow: auto;
    margin-top: 24px;
    padding: 0 40px 20px;
    height: fit-content;
    max-height: unset;
  }
  
  .testimonials > div > div:not(:last-child) {
    margin-right: 8px;
  }  

  .overlayLeft,
  .overlayRight {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .galleries {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 0 543px 227px;
    row-gap: 24px;
    padding-top: 0;
    width: 100vw;
  }

  .satu { 
    grid-area: satu; 
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .dua { 
    grid-area: dua;
    grid-column-start: 6;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .tiga { 
    grid-area: tiga; 
    grid-column-start: 8;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .empat { 
    grid-area: empat; 
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 10;
  }
  .lima { 
    grid-area: lima; 
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 5;
    grid-row-end: 8;
  }
  .enam { 
    grid-area: enam;
    grid-column-start: 1;
    grid-column-end: 10;
    grid-row-start: 10;
    grid-row-end: 15;
  }

  .tujuh, .delapan, .sembilan, .sepuluh, .sebelas {
    display: none;
  }

  .galleries > div:nth-child(2) {
    margin: 0;
    height: 543px;
    max-height: 543px;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 20px);
    grid-template-rows: repeat(15, 20px);
    gap: 16px;
    overflow-x: scroll;
  }

  .galleries > div:nth-child(2) > img {
    border-radius: 4px;
  }

  .galleries > div:nth-child(2) > button {
    width: fill;
    height: 48px;
    border-radius: 8px;
    padding: 16px;
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 8;
    grid-row-end: 10;
  }

  .galleries > div:nth-child(3) {
    padding: 24px 0;
  }
  .galleries > div:nth-child(3) > p  {
    padding-left: 40px;
    font-size: 24px;
    line-height: 27px;
    text-align: left;
    margin-bottom: 16px;
  }

  .galleries > div:nth-child(3) > div {
    display: flex;
    overflow: scroll;
    position: relative;
    padding: 0 40px 20px;
  }

  .galleries > div:nth-child(3) > div > div {
    margin-right: 10px;
  }

  .overlay {
    width: 100vw;
    height: 552px;
    top: -30px;
  }
}