.root {
  box-shadow: -0.6875rem 0.5625rem 1.3125rem rgba(173, 182, 217, 0.170884);
  border-radius: 0.3125rem;
  margin: 0 auto;
  max-width: fit-content;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  
}